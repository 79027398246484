import { PhoneContainer, PhoneTimeSignalWrap } from './preview.styles';
import { IoBatteryFullSharp, IoCellular } from 'react-icons/io5';
import { MdWifi } from 'react-icons/md';
import { BizMTemplateEntity } from '../../types/template.types';
import KakaoTemplate from './KakaoTemplate';
import SmsTemplate from './SmsTemplate';
import { BrandProperties } from '../../types/brand.types';

interface Iphone {
  children?: React.ReactNode;
  isKakao?: boolean;
  selectedTemplate?: BizMTemplateEntity | null;
  activeTab?: number;
  brandData?: BrandProperties;
}
const Phone = ({ isKakao, selectedTemplate, activeTab, children }: Iphone) => {
  const today = new Date();
  if (
    selectedTemplate &&
    selectedTemplate.buttons &&
    selectedTemplate.buttons.length > 0
  ) {
    for (let i = 0; i < selectedTemplate.buttons?.length; i++) {
      Object.assign(selectedTemplate, {
        [`button${i + 1}`]: selectedTemplate.buttons[i],
      });
    }
  }

  return (
    <PhoneContainer>
      <div>
        <div className="screen">
          <PhoneTimeSignalWrap>
            <div>
              {today.getHours().toString().padStart(2, '0')}:
              {today.getMinutes().toString().padStart(2, '0')}
            </div>
            <div className="verticalAlign">
              <IoCellular />
              <MdWifi />
              <IoBatteryFullSharp />
            </div>
          </PhoneTimeSignalWrap>
          {children && children}
          {!children && isKakao ? (
            <KakaoTemplate activeTab={activeTab} />
          ) : (
            <SmsTemplate />
          )}
        </div>
      </div>
    </PhoneContainer>
  );
};

export default Phone;

import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { TemplateContext } from '../../contexts/templates.context';
import { TemplateCategoryEnum } from '../../enums/templateForm.enums';
import ButtonPreview from '../ButtonPreview';
import {
  TemplateButtons,
  BizMTemplateEntity,
} from '../../types/template.types';
import HighlightVars from '../preview/HighlightVars.component';
import { BsArrowRight } from 'react-icons/bs';
import {
  MessageWrap,
  AlimtalkHeader,
  ImgStyled,
  EmphasizeTypeText,
  KakaoMessage,
  SmsMessageWrap,
  ButtonGroup,
  CarouselContainer,
  Extra,
  More,
  TemplateHeader,
  TemplateItem,
  TemplateWideItem,
  VideoContainer,
  Poster,
  Notice,
  Product,
  Price,
} from './Message.style';

type MessageContainerProps = {
  activeTab?: number;
  hideLogoHandle?: (value: boolean) => void;
};

export type NavigationHandle = {
  handlePrev: () => void;
  handleNext: () => void;
};

const MessageContainer = forwardRef<NavigationHandle, MessageContainerProps>(
  ({ activeTab = 0, hideLogoHandle }, ref) => {
    const { currentTemplateData, selectedTemplateCategory } =
      useContext(TemplateContext);

    const {
      message,
      smsMessage,
      templateExtra,
      templateHeader,
      templateEmphasizeType,
      templateTitle,
      templateSubtitle,
      templateImageUrl,
      smsTitle,
      templateItemHighlight,
      templateItem,
      summary,
      carousel,
      templateWideItem,
      messageType,
      templateCategory,
      fallback,
      video,
      commerce,
      additionalContent,
    } = currentTemplateData ?? {};

    const [swiper, setSwiper] = useState<SwiperCore>();
    const [buttons, setButtons] = useState<TemplateButtons[]>([]);
    const [isKakaoMessage, setIsKakaoMessage] = useState(false);
    const [videoId, setVideoId] = useState<string>();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const playVideo = () => {
      setIsVideoPlaying(!isVideoPlaying);
    };

    useImperativeHandle(ref, () => ({
      handlePrev() {
        if (swiper) {
          swiper.slidePrev();
        }
      },

      handleNext() {
        if (swiper) {
          swiper.slideNext();
        }
      },
    }));

    useEffect(() => {
      if (swiper) {
        swiper.slideTo(activeTab, 500);
      }
    }, [swiper, activeTab]);

    useEffect(() => {
      if (!currentTemplateData) {
        return;
      }
      setVideoId('');
      setButtons([]);
      const buttonsArr = [] as TemplateButtons[];
      for (let i = 1; i <= 5; ++i) {
        const buttonNum = `button${i}` as keyof BizMTemplateEntity;
        if (currentTemplateData[buttonNum] === undefined) break;
        buttonsArr.push(currentTemplateData[buttonNum] as TemplateButtons);
      }
      setButtons(buttonsArr);

      if (video?.video_url) {
        const id = video.video_url.split('/').pop();
        if (id?.includes('?')) {
          const idWithoutParams = id?.substring(0, id.indexOf('?'));
          setVideoId(idWithoutParams);
        } else {
          setVideoId(id);
        }
      }
    }, [currentTemplateData, video]);

    useEffect(() => {
      if (!selectedTemplateCategory) return;
      setIsKakaoMessage(
        selectedTemplateCategory.value === TemplateCategoryEnum.ALIMTALK ||
          selectedTemplateCategory.value === TemplateCategoryEnum.FRIENDTALK,
      );
    }, [selectedTemplateCategory]);

    if (!selectedTemplateCategory) return <></>;
    return (
      <>
        <MessageWrap
          $carousel={messageType === 'FC'}
          $isfriendtalk={selectedTemplateCategory.value === 'FRIENDTALK'}
          className={isKakaoMessage ? 'isKakaoMessage-noDashboard' : ''}
        >
          {((templateCategory === TemplateCategoryEnum.ALIMTALK &&
            selectedTemplateCategory.value !== 'SMS') ||
            selectedTemplateCategory.value ===
              TemplateCategoryEnum.ALIMTALK) && (
            <AlimtalkHeader>Info-talk</AlimtalkHeader>
          )}
          {currentTemplateData && !isKakaoMessage && smsTitle && (
            <SmsMessageWrap className="sms-title">{smsTitle}</SmsMessageWrap>
          )}
          {templateImageUrl && selectedTemplateCategory.value !== 'SMS' && (
            <ImgStyled
              src={templateImageUrl}
              alt="Header image"
              className={
                selectedTemplateCategory.value === 'SMS'
                  ? 'smsImage'
                  : templateCategory === TemplateCategoryEnum.ALIMTALK ||
                    selectedTemplateCategory.value ===
                      TemplateCategoryEnum.ALIMTALK
                  ? 'ATImage'
                  : ''
              }
            />
          )}
          {isKakaoMessage && messageType === 'FP' && videoId && (
            <VideoContainer>
              <iframe
                width="100%"
                height="auto"
                src={`https://play-tv.kakao.com/embed/player/cliplink/${videoId}?autoplay=${
                  isVideoPlaying ? 1 : 0
                }`}
                allow="autoplay; fullscreen; encrypted-media;"
              ></iframe>

              {!isVideoPlaying && video && video.thumbnail_url && (
                <Poster $image={video.thumbnail_url} onClick={playVideo} />
              )}
            </VideoContainer>
          )}
          {isKakaoMessage &&
            (templateEmphasizeType === 'ITEM_LIST' ||
              messageType === 'FL' ||
              messageType === 'FP') &&
            templateHeader && (
              <TemplateHeader $type={messageType}>
                {HighlightVars(templateHeader)}
              </TemplateHeader>
            )}

          {isKakaoMessage &&
            templateEmphasizeType === 'ITEM_LIST' &&
            templateItemHighlight && (
              <TemplateHeader>
                <div>
                  <div className="title">
                    {HighlightVars(templateItemHighlight.title)}
                  </div>
                  <div className="desc">
                    {HighlightVars(templateItemHighlight.description)}
                  </div>
                </div>
                {templateItemHighlight.imageUrl && (
                  <img
                    src={templateItemHighlight.imageUrl}
                    className="item-highlight-img"
                  />
                )}
              </TemplateHeader>
            )}

          {isKakaoMessage &&
            templateEmphasizeType === 'ITEM_LIST' &&
            templateItem && (
              <TemplateItem>
                <ul>
                  {templateItem.list.map((item, index) => {
                    return (
                      <li key={`list${index}`}>
                        <span className="label">{item.title}</span>
                        <span>{HighlightVars(item.description)}</span>
                      </li>
                    );
                  })}
                </ul>
                {summary && (
                  <ul>
                    <li>
                      <span className="label">{summary.title}</span>
                      <strong>{HighlightVars(summary.description)}</strong>
                    </li>
                  </ul>
                )}
                {templateItem.summary && (
                  <ul>
                    <li>
                      <span className="label">
                        {templateItem.summary.title}
                      </span>
                      <strong>
                        {HighlightVars(templateItem.summary.description)}
                      </strong>
                    </li>
                  </ul>
                )}
              </TemplateItem>
            )}

          {templateWideItem && isKakaoMessage && (
            <TemplateWideItem>
              <ul>
                {templateWideItem?.map((item, index) => {
                  return (
                    item.img_url && (
                      <li key={`list${index}`}>
                        <img src={item.img_url} />
                        <span className="label">{item.title}</span>
                      </li>
                    )
                  );
                })}
              </ul>
            </TemplateWideItem>
          )}

          {isKakaoMessage && messageType === 'FM' && commerce && (
            <Product>
              <div className="name">{commerce.title}</div>
              <Price>
                {commerce.regular_price && (
                  <div className="regular">
                    {new Intl.NumberFormat('ko-KR').format(
                      commerce.regular_price,
                    )}
                    원
                  </div>
                )}
                {commerce.discount_price && (
                  <div className="discount">
                    {new Intl.NumberFormat('ko-KR').format(
                      commerce.discount_price,
                    )}
                  </div>
                )}
                {commerce.discount_rate && (
                  <div className="rate">{commerce.discount_rate}%</div>
                )}
                {commerce.discount_fixed && (
                  <div className="rate fixed">
                    {new Intl.NumberFormat('ko-KR').format(
                      commerce.discount_fixed,
                    )}
                    원
                  </div>
                )}
              </Price>
            </Product>
          )}

          {isKakaoMessage &&
            templateEmphasizeType === 'TEXT' &&
            templateTitle && (
              <EmphasizeTypeText>
                <div className="emphSubTitle">{templateSubtitle}</div>
                <div className="emphTitle">{HighlightVars(templateTitle)}</div>
              </EmphasizeTypeText>
            )}
          {isKakaoMessage && messageType !== 'FL' && (
            <>
              <KakaoMessage $carousel={messageType === 'FC'}>
                {message && HighlightVars(message)}
              </KakaoMessage>
              {templateExtra && <Extra>{templateExtra}</Extra>}
            </>
          )}
          {isKakaoMessage && messageType === 'FM' && (
            <>{additionalContent && <Extra>{additionalContent}</Extra>}</>
          )}
          {selectedTemplateCategory.value === templateCategory &&
            selectedTemplateCategory.value === TemplateCategoryEnum.SMS && (
              // messageType !== 'FC' &&
              // messageType !== 'FL' &&
              <SmsMessageWrap className="last-message">
                {smsMessage && HighlightVars(smsMessage)}
              </SmsMessageWrap>
            )}
          {selectedTemplateCategory.value !== templateCategory && fallback && (
            <>
              {fallback.smsTitle && (
                <SmsMessageWrap className="sms-title">
                  <div>{HighlightVars(fallback.smsTitle)}</div>
                </SmsMessageWrap>
              )}
              {fallback.smsImage && (
                <ImgStyled src={fallback.smsImage} className="smsImage" />
              )}
              {fallback.smsMessage && (
                <SmsMessageWrap className="last-message">
                  <div className="desc">
                    {HighlightVars(fallback.smsMessage)}
                  </div>
                </SmsMessageWrap>
              )}
            </>
          )}
          {isKakaoMessage && messageType !== 'FC' && buttons && (
            <ButtonGroup
              className={
                messageType === 'FL' || messageType === 'FM' ? 'inline' : ''
              }
            >
              {buttons.map((button, index) => (
                <ButtonPreview
                  key={`${button.name}${index}`}
                  text={button.name}
                  type={button.type as string}
                  buttonData={button}
                />
              ))}
            </ButtonGroup>
          )}
          {isKakaoMessage && carousel?.list && (
            <CarouselContainer>
              <Swiper
                modules={[Navigation]}
                slidesPerView={1.4}
                spaceBetween={5}
                autoHeight={false}
                onSwiper={setSwiper}
                onAfterInit={(swiper: SwiperCore) => {
                  const index = swiper.activeIndex;
                  if (hideLogoHandle) {
                    hideLogoHandle(index !== 0);
                  }
                }}
                onSlideChange={(swiper: SwiperCore) => {
                  const index = swiper.activeIndex;
                  const length = swiper.slides.length;
                  swiper.allowSlideNext = index !== length - 2;
                  if (hideLogoHandle) {
                    hideLogoHandle(index !== 0);
                  }
                }}
              >
                {carousel.list.map((slide, index) => {
                  return (
                    <SwiperSlide key={`slide${index}`}>
                      {slide.image && slide.image.imageUrl && (
                        <img src={slide.image.imageUrl} />
                      )}
                      <div className="content">
                        <div className="title">{slide.header}</div>
                        <div className="desc">
                          {HighlightVars(slide.message)}
                        </div>
                      </div>
                      <div className="inlineButtons nogap">
                        {slide.buttons &&
                          slide.buttons.map((button) => (
                            <ButtonPreview
                              key={`slide-${button.name}`}
                              text={button.name}
                              type={button.type as string}
                              buttonData={button}
                            />
                          ))}
                      </div>
                    </SwiperSlide>
                  );
                })}
                {carousel.tail && (
                  <SwiperSlide className="more">
                    <More>
                      <BsArrowRight size="21px" />
                      <span>더보기</span>
                    </More>
                  </SwiperSlide>
                )}
                <SwiperSlide />
              </Swiper>
            </CarouselContainer>
          )}
        </MessageWrap>
        {messageType === 'FP' && (
          <Notice>
            Video preview may slightly differ from real sent message.
          </Notice>
        )}
      </>
    );
  },
);

MessageContainer.displayName = 'MessageContainer';
export default MessageContainer;
